<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{ i18n("Custom.PersonalityTemplateMaintenance") }}
      </span>
    </div>

    <div class="filter-block">
      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.TemplateName") }}</span>
        <input type="type" name="name" v-model="searchForm.Name" />
      </div>

      <div class="filter-field">
        <span class="title">{{ i18n("Custom.FeaturedContent") }} </span>
        <input type="type" name="name" v-model="searchForm.Trait" />
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.WhetherToEnable") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.IsEnabled"
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option :value="null" :label="i18n('Basic.All')"></el-option>
          <el-option :value="true" :label="i18n('Basic.Yes')"></el-option>
          <el-option :value="false" :label="i18n('Basic.No')"></el-option>
        </el-select>
      </div>

      <div class="filter-btn-wrap">
        <a class="btn btn-yellow mr-2" @click.prevent="getDataList">{{
          i18n("Basic.Search")
        }}</a>

        <a class="btn btn-blue" @click.prevent="openReMatchModal()">{{
          i18n("Custom.RecalculateTemplate")
        }}</a>
      </div>
    </div>

    <div class="text-right mb-2">
      <a class="btn btn-blue" @click.prevent="openDetailModal('add')">{{
        i18n("Basic.Add")
      }}</a>
    </div>

    <vue-bootstrap4-table
      :rows="dataList"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="IsEnabled" slot-scope="props">
        <span v-if="props.row.IsEnabled">{{ i18n("Basic.Yes") }}</span>
        <span v-if="!props.row.IsEnabled">{{ i18n("Basic.No") }}</span>
      </template>

      <template slot="func" slot-scope="props">
        <button
          class="btn btn-sm btn-blue mr-2"
          @click.prevent="openDetailModal('edit', props.row)"
        >
          {{ i18n("Basic.Edit") }}
        </button>
        <button
          class="btn btn-sm btn-primary"
          @click.prevent="openDetailModal('copy', props.row)"
        >
          {{ i18n("Custom.Copy") }}
        </button>
      </template>
    </vue-bootstrap4-table>

    <!-- detail modal -->
    <el-dialog
      top="40px"
      :close-on-click-modal="false"
      :lock-scroll="false"
      :append-to-body="true"
      class="dialog-form"
      :title="modalTitle()"
      :visible.sync="detailDialogVisible"
      width="80%"
    >
      <el-form :model="detailModal" label-position="top" ref="detailModal">
        <div class="modal-body" style="padding: 0; overflow-x: hidden">
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="i18n('Custom.TemplateNumber')"
                prop="Code"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-input type="text" maxlength="50" v-model="detailModal.Code" />
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="i18n('Custom.TemplateName')"
                prop="Name"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-input type="text" maxlength="50" v-model="detailModal.Name" />
              </el-form-item>
            </div>

            <div class="col-md-4">
              <el-form-item
                :label="i18n('Custom.SequenceJudgment')"
                prop="Seq"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-input-number
                  style="width: 100%"
                  v-model="detailModal.Seq"
                  :precision="0"
                  :step="1"
                  :min="1"
                  :controls="false"
                ></el-input-number>
              </el-form-item>
            </div>
            <div class="col-md-4">
              <el-form-item
                :label="i18n('Custom.PersonalityClassification')"
                prop="Category"
              >
                <el-select
                  style="width: 100%"
                  v-model="detailModal.Category"
                  placeholder="select"
                >
                  <el-option
                    v-for="option in categoryOptions"
                    :key="option.value"
                    :label="option.value"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="col-md-4">
              <div class="field">
                <el-form-item
                  :label="i18n('Custom.WhetherToEnable')"
                  prop="IsEnabled"
                  :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
                >
                  <el-select
                    style="display: block"
                    v-model="detailModal.IsEnabled"
                    filterable
                    :placeholder="i18n('Basic.PleaseSelect')"
                  >
                    <el-option :value="true" :label="i18n('Basic.Yes')"></el-option>
                    <el-option :value="false" :label="i18n('Basic.No')"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-12">
              <el-form-item
                :label="i18n('Custom.FeaturedContent')"
                prop="Trait"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-input
                  type="textarea"
                  maxlength="100"
                  v-model="detailModal.Trait"
                  rows="3"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-12">
              <el-form-item
                :label="i18n('Custom.TraitDescr')"
                prop="Description"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.TraitDescr"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                :label="i18n('Custom.TextDescription')"
                prop="Description"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.Description"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                :label="i18n('Custom.AdvantageDescr')"
                prop="AdvantageDescr"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.AdvantageDescr"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                :label="i18n('Custom.CommsWayDescr')"
                prop="CommsWayDescr"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.CommsWayDescr"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                :label="i18n('Custom.AdviceDescr')"
                prop="AdviceDescr"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.AdviceDescr"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                :label="i18n('Custom.AnimalPic')"
                prop="AnimalPicSrc"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <!-- <el-input type="text" maxlength="50" v-model="detailModal.ChartsPicBase64" /> -->

                <el-upload
                  class="avatar-uploader"
                  action=""
                  :show-file-list="false"
                  :auto-upload="false"
                  accept="image/jpeg,image/png"
                  :on-change="uploadAnimal"
                >
                  <img
                    v-if="detailModal.AnimalPicSrc"
                    :src="detailModal.AnimalPicSrc"
                    class="avatar"
                  />

                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                :label="i18n('Custom.PieChart')"
                prop="ChartsPicSrc"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-upload
                  class="avatar-uploader"
                  action=""
                  :show-file-list="false"
                  :auto-upload="false"
                  accept="image/jpeg,image/png"
                  :on-change="uploadFile"
                >
                  <img
                    v-if="detailModal.ChartsPicSrc"
                    :src="detailModal.ChartsPicSrc"
                    class="avatar"
                  />

                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>

              <el-form-item
                :label="i18n('Custom.PieChart') + '(' + i18n('Custom.CellPhone') + ')'"
                prop="ChartPicMobileSrc"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-upload
                  class="avatar-uploader"
                  action=""
                  :show-file-list="false"
                  :auto-upload="false"
                  accept="image/jpeg,image/png"
                  :on-change="uploadMobileFile"
                >
                  <img
                    v-if="detailModal.ChartPicMobileSrc"
                    :src="detailModal.ChartPicMobileSrc"
                    class="avatar"
                  />

                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>

              <el-form-item
                label="DISC人格特質應用說明"
                prop="DiscDescription"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.DiscDescription"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                label="積極力/老虎型特色說明"
                prop="Desc_Tiger"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-checkbox v-model="detailModal.Check_Tiger">是否符合</el-checkbox>
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.Desc_Tiger"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                label="影響力/孔雀型特色說明"
                prop="Desc_Peacock"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-checkbox v-model="detailModal.Check_Peacock">是否符合</el-checkbox>
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.Desc_Peacock"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                label="穩健力/無尾熊型特色說明"
                prop="Desc_Koala"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-checkbox v-model="detailModal.Check_Koala">是否符合</el-checkbox>
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.Desc_Koala"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <el-form-item
                label="邏輯力/貓頭鷹型特色說明"
                prop="Desc_Owl"
                :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
              >
                <el-checkbox v-model="detailModal.Check_Owl">是否符合</el-checkbox>
                <div style="line-height: normal; background-color: #fff">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="detailModal.Desc_Owl"
                    :options="editorOption"
                  />
                </div>
              </el-form-item>

              <!-- <div class="text-right">
                <el-button
                  type="primary"
                  size="small"
                  class="mb-1"
                  @click="openInfoModal()"
                  >{{ i18n("Basic.Add") }}</el-button
                >
              </div>
              <el-table
                :data="detailModal.Infos"
                style="width: 100%; margin-bottom: 10px"
              >
                <el-table-column
                  prop="Summary"
                  :label="i18n('Custom.Item')"
                ></el-table-column>
                <el-table-column
                  prop="Description"
                  :label="i18n('Custom.Description')"
                ></el-table-column>
                <el-table-column width="150">
                  <template slot-scope="props">
                    <button
                      class="btn btn-sm btn-blue mr-2"
                      @click="openInfoModal(props)"
                    >
                      {{ i18n("Basic.Edit") }}
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      @click="deleteItem(props, 'info')"
                    >
                      {{ i18n("Basic.Delete") }}
                    </button>
                  </template>
                </el-table-column>
              </el-table> -->

              <el-form-item :label="i18n('Custom.LogicalExplanation')">
                <el-input
                  type="textarea"
                  maxlength="500"
                  v-model="detailModal.LogicMemo"
                  rows="3"
                ></el-input>
              </el-form-item>

              <div class="text-right">
                <el-button
                  type="primary"
                  size="small"
                  class="mb-1"
                  @click="openLogicModal()"
                  >{{ i18n("Basic.Add") }}</el-button
                >
              </div>
              <el-table :data="detailModal.Logic" style="width: 100%">
                <el-table-column
                  prop="LeftOperant"
                  :label="i18n('Custom.LeftOperand')"
                  :formatter="operantText"
                >
                </el-table-column>
                <el-table-column
                  prop="ArithmeticOperator"
                  :label="i18n('Custom.ArithmeticOperator')"
                  :formatter="arithmeticOperatortText"
                >
                </el-table-column>
                <el-table-column
                  prop="RightOperant"
                  :label="i18n('Custom.RightOperand')"
                  :formatter="operantText"
                ></el-table-column>
                <el-table-column
                  prop="ComparisionOperator"
                  :label="i18n('Custom.ComparisonOperator')"
                  :formatter="comparisionOperatorText"
                ></el-table-column>
                <el-table-column prop="ComparisionConst" :label="i18n('Custom.Constant')">
                </el-table-column>
                <el-table-column label="刪除">
                  <template slot-scope="props">
                    <button
                      class="btn btn-sm btn-blue mr-2"
                      @click="openLogicModal(props)"
                    >
                      {{ i18n("Basic.Edit") }}
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      @click="deleteItem(props, 'logic')"
                    >
                      {{ i18n("Basic.Delete") }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <button
          type="button"
          class="btn btn-secondary mr-1"
          @click="detailDialogVisible = false"
        >
          {{ i18n("Basic.Close") }}
        </button>

        <button type="button" class="btn btn-blue" @click="saveDetail">
          {{ i18n("Basic.Save") }}
        </button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :title="i18n('Custom.AddDescription')"
      :visible.sync="infoDialogVisible"
      append-to-body
      ref="infoModal"
      width="30%"
    >
      <el-form :model="infoForm" ref="infoForm" label-width="100px" label-position="top">
        <el-form-item
          :label="i18n('Custom.Item')"
          prop="Summary"
          :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
        >
          <el-input v-model="infoForm.Summary" maxlength="50"></el-input>
        </el-form-item>

        <el-form-item
          :label="i18n('Custom.TextDescription')"
          prop="Description"
          :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
        >
          <div style="line-height: normal; background-color: #fff">
            <quill-editor
              ref="myQuillEditor"
              v-model="infoForm.Description"
              :options="editorOption"
            />
          </div>

          <!-- <el-input
            v-model="infoForm.Description"
            type="textarea"
            :rows="2"
            maxlength="1000"
            :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
          ></el-input> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="infoDialogVisible = false">{{
          i18n("Basic.Cancel")
        }}</el-button>
        <el-button type="primary" @click="pushInfo()">{{ i18n("Basic.Save") }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :title="i18n('Custom.AddLogic')"
      :visible.sync="logicDialogVisible"
      ref="logicModal"
      append-to-body
      width="30%"
    >
      <el-form :model="logicForm" ref="logicForm" label-width="100px">
        <el-form-item
          :label="i18n('Custom.LeftOperand')"
          prop="LeftOperant"
          :rules="{ required: true, message: 'required', trigger: 'blur' }"
        >
          <el-select
            style="width: 100%"
            v-model="logicForm.LeftOperant"
            placeholder="select"
          >
            <el-option
              v-for="option in operantOptions"
              :key="option.Value"
              :label="option.Text"
              :value="option.Value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="i18n('Custom.ArithmeticOperator')"
          prop="ArithmeticOperator"
        >
          <el-select
            style="width: 100%"
            v-model="logicForm.ArithmeticOperator"
            placeholder="select"
          >
            <el-option
              v-for="option in arithmeticOperatorOptions"
              :key="option.Value"
              :label="option.Text"
              :value="option.Value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="i18n('Custom.RightOperand')" prop="RightOperant">
          <el-select
            style="width: 100%"
            v-model="logicForm.RightOperant"
            placeholder="select"
          >
            <el-option
              v-for="option in operantOptions"
              :key="option.Value"
              :label="option.Text"
              :value="option.Value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="i18n('Custom.ComparisonOperator')"
          prop="ComparisionOperator"
          :rules="{ required: true, message: 'required', trigger: 'blur' }"
        >
          <el-select
            style="width: 100%"
            v-model="logicForm.ComparisionOperator"
            placeholder="select"
          >
            <el-option
              v-for="option in comparisionOperatorOptions"
              :key="option.Value"
              :label="option.Text"
              :value="option.Value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="i18n('Custom.ComparisonConstant')"
          prop="ComparisionConst"
          :rules="{ required: true, message: 'required', trigger: 'blur' }"
        >
          <el-input type="number" v-model="logicForm.ComparisionConst"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="logicDialogVisible = false">{{
          i18n("Basic.Cancel")
        }}</el-button>
        <el-button type="primary" @click="pushLogic()">{{
          i18n("Basic.Save")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :title="i18n('Custom.RecalculateTemplate')"
      :visible.sync="reMatchDialogVisible"
      ref="logicModal"
      append-to-body
      width="30%"
    >
      <div class="mb-2">{{ reMatchModal.Message }}</div>
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="reMatchModal.Percent"
      ></el-progress>

      <br /><br />
      <div class="text-center">
        <el-button
          @click="startReMatch()"
          v-if="reMatchModal.Status === 0"
          type="primary"
          size="mini"
          >{{ i18n("Custom.StartMatching") }}</el-button
        >
        <el-button
          @click="endReMatch()"
          v-if="reMatchModal.Status === 3 || reMatchModal.Status === 5"
          type="danger"
          size="mini"
          >{{ i18n("Custom.DeleteMatch") }}</el-button
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="reMatchDialogVisible = false">{{
          i18n("Basic.Close")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screen: null, // loading遮罩
      editStatus: null,
      detailModal: {},
      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      dataList: [],
      searchForm: {},
      classes: {
        table: "table-light table-striped",
        // via: 'table-danger'
      },

      //明細modal
      detailDialogVisible: false,

      infoDialogVisible: false,

      logicDialogVisible: false,

      reMatchDialogVisible: false,

      infoForm: {},

      logicForm: {
        LeftOperant: null,
        ArithmeticOperator: null,
        RightOperant: null,
        ComparisionOperator: null,
        ComparisionConst: null,
      },
      operantOptions: [],
      arithmeticOperatorOptions: [],
      comparisionOperatorOptions: [],
      key: 0,

      //進度條
      reMatchModal: {},

      //目前調整的項目index
      currentIndex: null,

      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            // ["link", "image", "video"],
          ],
        },
      },

      //分類
      categoryOptions:[],
      params:[],
    };
  },
  computed: {
    columns() {
      const col = [
        {
          label: this.i18n("Custom.TemplateNumber"),
          name: "Code",
          sort: true,
        },
        {
          label: this.i18n("Custom.TemplateName"),
          name: "Name",
          sort: true,
        },

        {
          label: this.i18n("Custom.SequenceJudgment"),
          name: "Seq",
          sort: true,
        },
        {
          label: this.i18n("Custom.FeaturedContent"),
          name: "Trait",
          sort: true,
        },
        {
          label: this.i18n("Custom.WhetherToEnable"),
          name: "IsEnabled",
          sort: true,
          slot_name: "IsEnabled",
        },
        {
          label: this.i18n("Custom.Function"),
          name: "func",
          slot_name: "func",
        },
      ];
      return col;
    },
  },
  methods: {
    //上傳圖片

    uploadAnimal(file) {
      const isIMAGE = file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 2;

      if (!isIMAGE) {
        this.$message.error("僅能上傳jpg/png格式!");
        return false;
      }
      if (!isLt5M) {
        this.$message.error("圖片大小不得超過2MB!");
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        // 圖片的 base64
        this.detailModal.AnimalPicSrc = e.target.result;
      };
    },
    // 上傳 檔案
    uploadFile(file) {
      const isIMAGE = file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 2;

      if (!isIMAGE) {
        this.$message.error("僅能上傳jpg/png格式!");
        return false;
      }
      if (!isLt5M) {
        this.$message.error("圖片大小不得超過2MB!");
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        // 圖片的 base64
        this.detailModal.ChartsPicSrc = e.target.result;
      };
    },

    // 上傳 手機版檔案
    uploadMobileFile(file) {
      const isIMAGE = file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 2;

      if (!isIMAGE) {
        this.$message.error("僅能上傳jpg/png格式!");
        return false;
      }
      if (!isLt5M) {
        this.$message.error("圖片大小不得超過2MB!");
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        // 圖片的 base64
        this.detailModal.ChartPicMobileSrc = e.target.result;
      };
    },

    operantText(row, column, cellValue) {
      if (cellValue) {
        let currentItem = this.operantOptions.find((item) => {
          return Number(item.Value) === Number(cellValue);
        });
        return currentItem.Text;
      }
    },

    arithmeticOperatortText(row, column, cellValue) {
      if (cellValue) {
        let currentItem = this.arithmeticOperatorOptions.find((item) => {
          return Number(item.Value) === Number(cellValue);
        });
        return currentItem.Text;
      }
    },

    comparisionOperatorText(row, column, cellValue) {
      if (cellValue) {
        let currentItem = this.comparisionOperatorOptions.find((item) => {
          return Number(item.Value) === Number(cellValue);
        });
        return currentItem.Text;
      }
    },

    modalTitle() {
      switch (this.editStatus) {
        case "add":
          return "新增";
        case "copy":
          return "複製";
        case "edit":
          return "編輯";
      }
    },

    //重算對應模板
    openReMatchModal() {
      const vm = this;
      vm.getReMatch();
      vm.reMatchDialogVisible = true;
    },

    //取得重算進度
    getReMatch() {
      const url = `${window.BaseUrl.api}/Eva/Pattern/ReMatch`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.reMatchModal = {
              ...response.data.Data,
            };
            if (
              this.reMatchDialogVisible === true &&
              this.reMatchModal.FinishTime === null &&
              this.reMatchModal.Status !== 0
            ) {
              setTimeout(() => {
                this.getReMatch();
              }, "1000");
            }
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
        });
    },

    //開始重算
    startReMatch() {
      const url = `${window.BaseUrl.api}/Eva/Pattern/ReMatch`;
      this.$http
        .post(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.getReMatch();
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
        });
    },

    //終止重算
    endReMatch() {
      const url = `${window.BaseUrl.api}/Eva/Pattern/ReMatch`;
      this.$http
        .delete(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.getReMatch();
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
        });
    },

    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    //取得列表
    getDataList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Pattern`;
      const params = { ...this.searchForm };
      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.dataList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
          this.screen.close();
        });
    },

    //打開明細
    openDetailModal(type, row) {
      this.editStatus = type;

      if (row) {
        this.screen = this.openLoading();
        const url = `${window.BaseUrl.api}/Eva/Pattern/${row.Guid}`;
        this.$http
          .get(url)
          .then((response) => {
            if (response.data.ErrorMessage) {
              this.$notify({
                title: "錯誤",
                message: response.data.ErrorMessage,
                type: "error",
              });
            } else {
              this.detailModal = {
                ...response.data.Data,
                AnimalPicSrc: response.data.Data.AnimalPicSrc
                  ? response.data.Data.AnimalPicSrc
                  : null,
                ChartsPicSrc: response.data.Data.ChartsPicSrc
                  ? response.data.Data.ChartsPicSrc
                  : null,
                ChartPicMobileSrc: response.data.Data.ChartPicMobileSrc
                  ? response.data.Data.ChartPicMobileSrc
                  : null,
              };

              //如果是複製前三欄清空
              if (type === "copy") {
                this.detailModal.Code = null;
                this.detailModal.Name = null;
                this.detailModal.Seq = undefined;
              }

              this.detailDialogVisible = true;

              this.$nextTick(() => {
                this.$refs.detailModal.clearValidate();
              });
            }
            this.screen.close();
          })
          .catch((error) => {
            this.$notify({
              title: "錯誤",
              message: error.response.data.ErrorMessage,
              type: "error",
            });
            this.screen.close();
          });
      } else {
        this.detailModal = {
          IsEnabled: true,
          Infos: [],
          Logic: [],
          AnimalPicSrc: null,
          ChartsPicSrc: null,
          ChartPicMobileSrc: null,
        };

        this.detailDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.detailModal.clearValidate();
        });
      }
    },

    //儲存明細
    saveDetail() {
      this.$refs.detailModal.validate((valid) => {
        if (valid) {
          this.screen = this.openLoading();
          const url =
            this.editStatus === "add" || this.editStatus === "copy"
              ? `${window.BaseUrl.api}/Eva/Pattern`
              : `${window.BaseUrl.api}/Eva/Pattern/${this.detailModal.Guid}`;
          const params = { ...this.detailModal };
          let httpMethods =
            this.editStatus === "add" || this.editStatus === "copy" ? "post" : "put";
          this.$http[httpMethods](url, params)
            .then((response) => {
              if (response.data.ErrorMessage) {
                this.$notify({
                  title: "錯誤",
                  message: response.data.ErrorMessage,
                  type: "error",
                });
              } else {
                this.getDataList();
                this.detailDialogVisible = false;
                this.$notify({
                  title: "成功",
                  message: "儲存成功",
                  type: "success",
                });
              }
              this.screen.close();
            })
            .catch((error) => {
              this.$notify({
                title: "錯誤",
                message: error.response.data.ErrorMessage,
                type: "error",
              });
              this.screen.close();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //取得運算元
    getOperantOptions() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Pattern/Options/Operant`;

      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.operantOptions = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
          this.screen.close();
        });
    },

    //取得算式運算子
    getArithmeticOperatorOptions() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Pattern/Options/ArithmeticOperator`;

      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.arithmeticOperatorOptions = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
          this.screen.close();
        });
    },

    //取得比較運算子
    getComparisionOperatorOptions() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Pattern/Options/ComparisionOperator`;

      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.comparisionOperatorOptions = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
          this.screen.close();
        });
    },

    openInfoModal(item) {
      if (item) {
        this.currentIndex = item.$index;
        this.infoForm = { ...item.row };
        this.infoDialogVisible = true;
      } else {
        this.currentIndex = null;
        this.infoForm = {};
        this.infoDialogVisible = true;

        this.$nextTick(() => {
          this.$refs.infoForm.clearValidate();
        });
      }
    },

    openLogicModal(item) {
      if (item) {
        this.currentIndex = item.$index;
        this.logicForm = { ...item.row };
        this.logicDialogVisible = true;
      } else {
        this.currentIndex = null;
        this.logicForm = {};
        this.logicDialogVisible = true;

        this.$nextTick(() => {
          this.$refs.logicForm.clearValidate();
        });
      }
    },

    //儲存邏輯
    pushInfo() {
      this.$refs.infoForm.validate((valid) => {
        if (valid) {
          if (this.currentIndex === null) {
            this.detailModal.Infos.push({ ...this.infoForm });
          } else {
            this.detailModal.Infos.splice(this.currentIndex, 1, { ...this.infoForm });
          }
          this.infoDialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //儲存邏輯
    pushLogic() {
      this.$refs.logicForm.validate((valid) => {
        if (valid) {
          if (this.currentIndex === null) {
            this.detailModal.Logic.push({ ...this.logicForm });
          } else {
            this.detailModal.Logic.splice(this.currentIndex, 1, { ...this.logicForm });
          }
          this.logicDialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    deleteItem(row, type) {
      let index = row.$index;
      if (type === "info") {
        this.detailModal.Infos.splice(index, 1);
      } else {
        this.detailModal.Logic.splice(index, 1);
      }
    },


    //取得分類
    getCategoryOptions(){

      this.screen = this.openLoading();
      this.params =[];
      const url = `${window.BaseUrl.api}/system/config`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            let arr = [];
            arr = Object.keys(response.data.Data);

            for (let i = 1; i < arr.length; ++i) {
              this.params.push({
                title: arr[i],
                content: response.data.Data[arr[i]],
              });
            }
          }

          let modal = this.params.find(item=>{
            return item.title==="人格特質模板維護"
          })


          let category = modal.content.find(item=>{
            return item.ConfigKey==="ModuleCategoty"
          })


          this.categoryOptions = category.Value.split('、').map(item=>{
            return {
              value:item,
            }
          });


          this.screen.close();
        })
        .catch(() => {
          this.screen.close();
        });

    }
  },
  created() {
    this.getDataList();
    this.getOperantOptions();
    this.getArithmeticOperatorOptions();
    this.getComparisionOperatorOptions();
    this.getCategoryOptions();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ql-editor {
  min-height: 120px;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  object-fit: contain;
}
</style>
